import React from 'react'

import HomeHero from './HomeHero'
import ProductsBlock from './ProductsBlock'
import FeaturesPrimary from './FeaturesPrimary'
import FeaturesSecondary from './FeaturesSecondary'
import ProductsBlockEnd from './ProductsBlockEnd'
import RequestDemoCtaBlock from '../../components/RequestDemoCtaBlock'

const LayoutHome = () => (
  <div>
    <HomeHero />
    <ProductsBlock />
    <FeaturesPrimary />
    <FeaturesSecondary />
    <ProductsBlockEnd />
    <RequestDemoCtaBlock />
  </div>
)

export default LayoutHome
