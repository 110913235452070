import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import LayoutHome from '../pageLayouts/LayoutHome'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Data Analytics and Decision Support Platform for Market Access"
      keywords={[
        'pulse digital',
        'market access',
        'decision support',
        'healthcare',
        'react',
        'software engineering',
        'new york',
        'nyc',
        'pulse',
        'javascript',
        'analytics',
        'development',
        'payer',
        'payer polices',
        'formulary',
        'tracking',
        'quality of access',
        'payer access',
        'providers',
        'provider',
        'hospitals',
        'community practices',
        'oncology',
        'specialty',
        'dashboard',
        'key account',
        'key account management',
        'field team',
        'tracking',
        'pathways',
        'alternative payment models',
        'value based care',
        'quality programs',
        'tracking',
      ]}
    />
    <LayoutHome />
  </Layout>
)

export default IndexPage
