import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ContentContainer } from '../../Grid'

import { H2, H5, Paragraph } from '../../Typography'
import { AlphaColors, Colors, Gutter } from '../../../utils/styles'

const SectionHeaderContainer = styled(ContentContainer)(
  {
    marginBottom: 32,
    textAlign: 'center',
    '@media (max-width: 640px)': {
      textAlign: 'left',
    },
  },
  props => ({
    ...props.style,
  })
)

const SectionHeaderLabel = styled(H5)(props => ({
  color: props.labelColor,
  fontSize: 16,
  letterSpacing: 1,
  marginBottom: 8,
  textTransform: 'uppercase',
}))

const SectionHeaderTitle = styled(H2)(props => ({
  color: props.titleColor,
  fontSize: 36,
  lineHeight: 1.3,
  marginBottom: Gutter.FULL,
}))

const SectionHeaderDescription = styled(Paragraph)(props => ({
  color: props.descriptionColor,
  fontSize: 20,
  lineHeight: '30px',
}))

const SectionHeader = props => (
  <SectionHeaderContainer style={props.style} width={props.width} m="auto">
    <SectionHeaderLabel labelColor={props.labelColor}>{props.label}</SectionHeaderLabel>
    <SectionHeaderTitle titleColor={props.titleColor}>{props.title}</SectionHeaderTitle>
    <SectionHeaderDescription descriptionColor={props.descriptionColor}>
      {props.description}
    </SectionHeaderDescription>
  </SectionHeaderContainer>
)

export default SectionHeader

SectionHeader.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  style: PropTypes.shape,
  width: PropTypes.arrayOf,
  titleColor: PropTypes.string,
  labelColor: PropTypes.string,
  descriptionColor: PropTypes.string,
}

SectionHeader.defaultProps = {
  label: 'Label',
  title: 'Header',
  description: 'descriptions',
  style: {},
  width: [1, 1, 1 / 2],
  titleColor: Colors.BLACK,
  descriptionColor: AlphaColors.BLACK_80,
  labelColor: Colors.PRIMARY,
}
