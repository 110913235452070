import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'
import { Link } from 'gatsby'
import Image from '../Image'

import {
  Colors, Gutter, MediaQueries, Transitions
} from '../../utils/styles'
import { Paragraph } from '../Typography'

const ProductCardLayout = styled(Box)({
  padding: Gutter.HALF,
  // flex: 'auto',
})

const ProductIconContainer = styled.div({
  marginBottom: Gutter.HALF,
})

const Arrow = styled.div({
  fontsize: 24,
  marginLeft: Gutter.QUARTER,
  transition: `transform ${Transitions.NORMAL}`,
})

const boxShadowColor = '6,86,217'

const ProductCardTitle = styled.h3({
  color: Colors.BLACK,
  fontSize: 22,
  fontWeight: 700,
  lineHeight: '26px',
  marginBottom: 12,
  transition: `color ${Transitions.NORMAL}`,
})

const Label = styled.div`
  color: ${Colors.PRIMARY};
  display: flex;
  font-size: 16px;
  font-weight: 700;
  opacity: 0;
  transition: opacity ${Transitions.NORMAL};
  @media (max-width: ${MediaQueries.MOBILE}) {
    opacity: 1;
  }
`

const ProductCardIndicator = styled.div({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '16px',
  textDecoration: 'none',
  transition: `color ${Transitions.NORMAL}`,
})

const ProductCardContainer = styled(Link)({
  alignItems: 'center',
  background: Colors.WHITE,
  borderRadius: 4,
  boxShadow: `0 8px 32px 0 rgba(${boxShadowColor},0.16)`,
  display: 'flex',
  flexDirection: 'column',
  padding: Gutter.FULL,
  textAlign: 'center',
  textDecoration: 'none',
  height: '100%',
  transition: `box-shadow, ${Transitions.NORMAL}, transform ${Transitions.NORMAL}`,
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    display: 'inline-block',
    textAlign: 'center',
  },
  ':hover': {
    boxShadow: `0 8px 32px 0 rgba(${boxShadowColor},0.30)`,
    transform: 'translateY(-12px)',
    [Arrow]: {
      transform: `translateX(${Gutter.HALF})`,
    },
    [ProductCardTitle]: {
      color: Colors.PRIMARY,
    },
    [Label]: {
      opacity: 1,
    },
  },
})

const ProductCardDescription = styled(Paragraph)({
  color: Colors.BLACK,
  marginBottom: Gutter.FULL,
})

const ProductCardIndicatorContainer = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
})

const ProductCard = props => (
  <ProductCardLayout width={[1, 1, 1 / 3]}>
    <ProductCardContainer to={props.to}>
      <ProductIconContainer>
        <Image height="48px" src={props.src} />
      </ProductIconContainer>
      <ProductCardTitle>{props.title}</ProductCardTitle>
      <ProductCardDescription>{props.description}</ProductCardDescription>
      <ProductCardIndicatorContainer>
        <ProductCardIndicator>
          <Label>
            View Tool
            {' '}
            <Arrow>→</Arrow>
          </Label>
        </ProductCardIndicator>
      </ProductCardIndicatorContainer>
    </ProductCardContainer>
  </ProductCardLayout>
)

export default ProductCard

ProductCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  to: PropTypes.string,
  src: PropTypes.string,
}

ProductCard.defaultProps = {
  title: 'card title',
  description: 'card description',
  to: '/',
  src: '',
}
