import React from 'react'

import Section from '../../../components/Section'
import SectionHeader from '../../../components/Section/SectionHeader'
import { GridContainer } from '../../../components/Grid'
import VerticalFeatureBlock from '../../../components/VerticalFeatureBlock'

import assets from '../../../content/assets'
import { AlphaColors, Colors } from '../../../utils/styles'

const featureBlockWidth = [1, 1 / 2, 1 / 3]

const FeaturesSecondary = () => (
  <Section backgroundImage={assets.images.target1}>
    <GridContainer>
      <SectionHeader
        label="How"
        // eslint-disable-next-line
        title="Address Key Business Questions at Appropriate Frequency for Leadership Focus & Engagement Execution"
        // eslint-disable-next-line
        description="Timely, validated information is structured for aggregate management insights & account level engagement planning, supported by live Q&A/review sessions that enhance team fluency & ensure strategic planning focus."
        style={{ textAlign: 'center' }}
        labelColor={Colors.WHITE}
        titleColor={Colors.WHITE}
        descriptionColor={AlphaColors.WHITE_90}
      />
    </GridContainer>
    <GridContainer flexWrap="wrap" justifyContent="center">
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565374570/pulse-analytics-marketing/icons/feature-right-information-time.svg"
        title="Right Information, Right Time"
        // eslint-disable-next-line
        description="High-quality information updated regularly & validated via propietary analog dataset precedent & forward looking insights from our expert panel"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565374570/pulse-analytics-marketing/icons/feature-channel-account-insights.svg"
        title="Channel & Account Level Insights"
        // eslint-disable-next-line
        description="Structured information to address both aggregate channel emerging priorities & granular account level engagement targets"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565374570/pulse-analytics-marketing/icons/feature-live-education-support.svg"
        title="Live Education & Planning Support"
        // eslint-disable-next-line
        description="Monthly update review teleconferences provide live Q&A for enhanced team fluency & strategic planning focus areas for execution"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565374570/pulse-analytics-marketing/icons/feature-proactive-surveillance.svg"
        title="Proactive Surveillance & Alerts"
        // eslint-disable-next-line
        description="Customized alerts via email communicate critical changes identified through proactive market surveillance of key customer/influencer issues"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565374570/pulse-analytics-marketing/icons/feature-custom-reporting-analytics.svg"
        title="Custom Reporting & Analytics"
        // eslint-disable-next-line
        description="Strategic Dashboard Toolkit allows users to “build” their own PowerPoint reports and download Excel files for further custom analysis"
        width={featureBlockWidth}
      />
    </GridContainer>
  </Section>
)

export default FeaturesSecondary
