import React from 'react'
import styled from '@emotion/styled'

import { GridContainer, ContentContainer } from '../../../components/Grid'
import { H1, H4 } from '../../../components/Typography'
import Image from '../../../components/Image'
import ButtonLink from '../../../components/Buttons'
import ButtonShare from '../../../components/Buttons/ButtonShare'

import {
  Colors, Gutter, Heights, MediaQueries
} from '../../../utils/styles'

import assets from '../../../content/assets'

const SectionContainer = styled.div`
  width: 100%;
  background: url(${assets.images.fluidBubblesPrimary1}), ${Colors.LIGHT_BLUE_GRAY};
  backgroundrepeat: no-repeat;
  backgroundpositionx: center;
  backgroundpositiony: center;
  webkitbackgroundsize: cover;
  mozbackgroundsize: cover;
  obackgroundsize: cover;
  backgroundsize: cover;
  position: relative;
  padding: ${Gutter.TRIPLE} 0;
  @media (max-width: ${MediaQueries.MOBILE}) {
    padding: ${Gutter.FULL} 0;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) : {
    margin-top: ${Heights.PRIMARY_NAV_BAR};
  }
`

const StyledH1 = styled(H1)`
  color: ${Colors.WHITE};
  margin-bottom: ${Gutter.HALF};
  @media (max-width: ${MediaQueries.MOBILE}) {
    padding-top: 0;
    font-size: 32px;
    line-height: 1.4;
  }
`

const StyledH4 = styled(H4)`
  color: ${Colors.WHITE};
  font-weight: 500;
  margin-bottom: ${Gutter.FULL};
  fontsize: 22px;
  max-width: 600;
  @media (max-width: ${MediaQueries.MOBILE}) {
    font-size: 18px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  @media (max-width: ${MediaQueries.MOBILE}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const ButtonExternalContainer = styled.div`
  margin-left: ${Gutter.FULL};
  @media (max-width: ${MediaQueries.MOBILE}) {
    margin-left: 0;
    margin-top: ${Gutter.FULL};
  }
`

const HomeHero = () => (
  <div>
    <SectionContainer>
      <GridContainer alignItems="center" flexWrap="wrap">
        <ContentContainer width={[1, 1, 1 / 2]} p={[Gutter.HALF, Gutter.FULL, Gutter.FULL]}>
          <StyledH1>
            Decision Support Tools for Market Access in Oncology & Specialty Therapeutics
          </StyledH1>
          <StyledH4 style={{}}>
            Transforming data into insights & strategies to communicate the right priorities to your
            team & customers
          </StyledH4>
          <ButtonContainer>
            <ButtonLink
              to="/request-demo/"
              buttonColor={Colors.WHITE}
              textColor={Colors.PRIMARY}
              id="button--cta__request-demo"
              style={{
                width: 'min-content',
              }}
            >
              Request Demo
            </ButtonLink>
            <ButtonExternalContainer>
              <ButtonShare />
            </ButtonExternalContainer>
          </ButtonContainer>
        </ContentContainer>
        <ContentContainer width={[1, 1, 1 / 2]} style={{ padding: Gutter.FULL }}>
          <Image
            src={assets.images.homeProductScreenHero}
            alt="Pulse Analytics Product Screenshot"
          />
        </ContentContainer>
      </GridContainer>
    </SectionContainer>
  </div>
)

export default HomeHero
