import React from 'react'

import Section from '../../../components/Section'
import SectionHeader from '../../../components/Section/SectionHeader'
import { GridContainer } from '../../../components/Grid'
import VerticalFeatureBlock from '../../../components/VerticalFeatureBlock'

import { Colors } from '../../../utils/styles'

import assets from '../../../content/assets'

const featureBlockWidth = [1, 1 / 2, 1 / 3]
const sectionTitleColor = Colors.SECONDARY

const FeaturesPrimary = () => (
  <Section backgroundImage={assets.images.noise2Secondary}>
    <GridContainer>
      <SectionHeader
        label="Why"
        labelColor={sectionTitleColor}
        // eslint-disable-next-line
        title="Filter “Noise” of Complex, Rapidly Evolving  Landscape & Focus on What Impacts Business"
        // eslint-disable-next-line
        description="Pulse Analytics addresses key challenges facing pharmaceutical executives operating in a highly dynamic specialty access & competitive arena, while building & directing teams of various experience levels."
        style={{ textAlign: 'center' }}
      />
    </GridContainer>
    <GridContainer flexWrap="wrap" justifyContent="center">
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565373919/pulse-analytics-marketing/icons/feature-predictive-analytics.svg"
        title="Predictive Analytics"
        titleColor={sectionTitleColor}
        // eslint-disable-next-line
        description="Robust access to historical analogs that inform forecasting & scenario planning"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565373919/pulse-analytics-marketing/icons/feature-smart-targeting.svg"
        title="Smart Targeting"
        titleColor={sectionTitleColor}
        // eslint-disable-next-line
        description="Prioritization of key trends, drivers & top accounts vs. “nice to know” details"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565373919/pulse-analytics-marketing/icons/feature-strategic-support.svg"
        title="Strategic Support"
        titleColor={sectionTitleColor}
        // eslint-disable-next-line
        description="Analytical support to aid in translating insights to implications for strategic engagement planning"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565373919/pulse-analytics-marketing/icons/feature-expert-validation.svg"
        title="Expert Validation"
        titleColor={sectionTitleColor}
        // eslint-disable-next-line
        description="Extensive & frequent validation of data by specialized teams & access panel of payers, providers, & emerging access influencers"
        width={featureBlockWidth}
      />
      <VerticalFeatureBlock
        // eslint-disable-next-line
        icon="https://res.cloudinary.com/pulsedatatools/image/upload/v1565373919/pulse-analytics-marketing/icons/feature-organizational-alignment.svg"
        title="Organizational Alignment"
        titleColor={sectionTitleColor}
        // eslint-disable-next-line
        description="Efficient communication of customer & influencer changes for leadership briefing, team alignment, & field guidance"
        width={featureBlockWidth}
      />
    </GridContainer>
  </Section>
)

export default FeaturesPrimary
