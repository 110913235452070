import React from 'react'
import styled from '@emotion/styled'

import { Paragraph } from '../Typography'
import { Gutter } from '../../utils/styles'

const FootnoteContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  padding: Gutter.FULL,
})

const Footnote = styled(Paragraph)({
  fontStyle: 'italic',
  fontSize: 12,
  opacity: 0.5,
})

const ProductFootnote = () => (
  <FootnoteContainer>
    <Footnote>* pathways only refers to oncology brands</Footnote>
  </FootnoteContainer>
)

export default ProductFootnote
