import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'

import Image from '../Image'
import { H4, Paragraph } from '../Typography'

import { AlphaColors, Colors, Gutter } from '../../utils/styles'

const FeatureBlockContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: Gutter.HALF,
})

const FeatureBlockContent = styled.div({
  background: Colors.WHITE,
  borderRadius: 8,
  boxShadow: `0 4px 24px 0px ${AlphaColors.PRIMARY_20}`,
  height: '100%',
  padding: `24px ${Gutter.FULL}`,
  width: '100%',
})

const FeatureBlockIconContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: Gutter.HALF,
})

const FeatureBlockTextContainer = styled.div({
  textAlign: 'center',
})

const VerticalFeatureBlock = props => (
  <FeatureBlockContainer width={props.width}>
    <FeatureBlockContent>
      <FeatureBlockIconContainer>
        <Image src={props.icon} width={props.iconSize} height={props.iconSize} />
      </FeatureBlockIconContainer>
      <FeatureBlockTextContainer>
        <H4 style={{ color: props.titleColor }}>{props.title}</H4>
        <Paragraph>{props.description}</Paragraph>
      </FeatureBlockTextContainer>
    </FeatureBlockContent>
  </FeatureBlockContainer>
)

export default VerticalFeatureBlock

VerticalFeatureBlock.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  width: PropTypes.arrayOf,
  iconSize: PropTypes.string,
}

VerticalFeatureBlock.defaultProps = {
  icon:
    // eslint-disable-next-line
    'https://res.cloudinary.com/pulsedatatools/image/upload/v1560958986/pulse-analytics-marketing/icons/placeholder-icon.svg',
  title: 'Feature Block Title',
  titleColor: Colors.PRIMARY,
  description: 'A feature block description that explains a feature in a summary',
  width: [1, 1 / 2, 1 / 4],
  iconSize: 60,
}
