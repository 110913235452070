import React from 'react'

import Section from '../../../components/Section'
import SectionHeader from '../../../components/Section/SectionHeader'
import { GridContainer } from '../../../components/Grid'
import ProductCard from '../../../components/Cards'
import ProductFootnote from '../../../components/SectionProductsBlock'

import toolContent from '../../../content/toolContent'

const ProductsBlockEnd = () => (
  <Section>
    <GridContainer>
      <SectionHeader
        label="LEARN MORE"
        title="Explore Our Tool Capabilities"
        description={null}
        style={{
          textAlign: 'center',
        }}
      />
    </GridContainer>
    <GridContainer flexWrap="wrap">
      <ProductCard
        title={toolContent.title.payer}
        description={toolContent.shortDescription.payer}
        to={toolContent.link.payer}
        src={toolContent.icon.payer}
      />
      <ProductCard
        title={toolContent.title.provider}
        description={toolContent.shortDescription.provider}
        to={toolContent.link.provider}
        src={toolContent.icon.provider}
      />
      <ProductCard
        title={toolContent.title.valueBasedModels}
        description={toolContent.shortDescription.valueBasedModels}
        to={toolContent.link.valueBasedModels}
        src={toolContent.icon.valueBasedModels}
      />
    </GridContainer>
    <GridContainer>
      <ProductFootnote />
    </GridContainer>
  </Section>
)

export default ProductsBlockEnd
