import React from 'react'

import Section from '../../../components/Section'
import SectionHeader from '../../../components/Section/SectionHeader'
import { GridContainer } from '../../../components/Grid'
import ProductCard from '../../../components/Cards'
import ProductFootnote from '../../../components/SectionProductsBlock'

import toolContent from '../../../content/toolContent'

const ProductsBlock = () => (
  <Section>
    <GridContainer>
      <SectionHeader
        label="What"
        title="Market Access, Brand/Portfolio & Sales Leadership Planning Tools"
        // eslint-disable-next-line
        description="Pulse Analytics provides key decision support tools that span priority customers & influencers of access and value to support targeted engagement planning."
        style={{
          textAlign: 'center',
        }}
      />
    </GridContainer>
    <GridContainer flexWrap="wrap">
      <ProductCard
        title={toolContent.title.payer}
        description={toolContent.shortDescription.payer}
        to={toolContent.link.payer}
        src={toolContent.icon.payer}
      />
      <ProductCard
        title={toolContent.title.provider}
        description={toolContent.shortDescription.provider}
        to={toolContent.link.provider}
        src={toolContent.icon.provider}
      />
      <ProductCard
        title={toolContent.title.valueBasedModels}
        description={toolContent.shortDescription.valueBasedModels}
        to={toolContent.link.valueBasedModels}
        src={toolContent.icon.valueBasedModels}
      />
      <ProductFootnote />
    </GridContainer>
  </Section>
)

export default ProductsBlock
